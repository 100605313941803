import { ConfirmationModal } from "@components/Shared";
import {
    batchMethodDelete,
    deleteDevice,
    Device,
    DeviceBatchInfoEditFormModal,
    DeviceEditFormModal,
    deviceReducer,
    DevicesListResponse,
    DeviceTableBody,
    DeviceTableContainer,
    DeviceTableHeader,
    DeviceWorkerChangeModel,
    getDevices,
    isSelectAll,
    LicenseStatus,
    ManageLicenses,
    modalReducer,
    updateOnBatchInfoEdit,
    updateOnCustomLicenseChange,
    updateOnIndividualEdit,
    updateOnLicenseChange,
} from "@features/devices";
import { useApiError, useColorMode, useNotification, useProducts } from "@lib/provider";
import { getSortPrefs, setSortPrefs } from "@lib/utils";
import { TableBody, useMediaQuery } from "@mui/material";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

interface MyDevicesProps {}

const MyDevices: React.FC<MyDevicesProps> = () => {
    const queryClient = useQueryClient();
    const { isLoadingProducts } = useProducts();
    const [row, setRow] = useState(10);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [pageLoad, setPageLoad] = useState(false);
    const { product } = useColorMode();

    const [state, dispatcher] = useReducer(deviceReducer, {
        devices: [],
        selected_devicesIds: [],
        // devices: [],
        isSearching: false,
        sortState: "NONE",
        total: 0,
        fetched: false,
    });

    useEffect(() => {
        const sortPrefs = getSortPrefs();

        setPage(sortPrefs?.page || 1);
        setRow(sortPrefs?.row || 10);
        if (sortPrefs?.sort) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dispatcher({ type: sortPrefs?.sort as any });
        }
        setPageLoad(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { data, isLoading, refetch } = useQuery(
        ["devices", page, row, search, product],
        () => getDevices(page, row, search, product),
        {
            enabled: pageLoad,
        }
    );

    const { addNotification } = useNotification();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const { addError } = useApiError();

    const refreshData = async () => {
        setIsRefreshing(true);
        try {
            queryClient.invalidateQueries("user");
            await refetch();
            addNotification({ message: "Successfully refreshed data", type: "success" });
        } catch (_error) {
            addError({ error: _error });
        }
        setIsRefreshing(false);
    };

    useEffect(() => {
        dispatcher({ type: "PRODUCT_CHANGED" });
    }, [product]);

    const { mutateAsync: mutateAsyncBatchDelete } = useMutation(batchMethodDelete);
    const { mutateAsync: mutateAsyncDelete } = useMutation(deleteDevice);

    const [modalState, modalDispatcher] = useReducer(modalReducer, {
        delete: false,
        edit: false,
        delete_batch: false,
        edit_batch: false,
        license_batch: false,
    });

    const [deleteDeviceState, setDeleteDeviceState] = useState<Device>({
        id: -1,
        device_name: "",
        license_status: 2,
        uses_own_config: false,
        product_id: "",
    });
    const [editDevice, setEditDevice] = useState<Device>({
        id: -1,
        device_name: "",
        license_status: 2,
        uses_own_config: false,
        product_id: "",
    });

    const isSmallScreen = useMediaQuery("(max-width: 432px)");

    const handleSetData = useCallback(() => {
        if (data?.items) {
            dispatcher({ type: "FETCH", data: data.items, total: data?.total });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        handleSetData();
    }, [handleSetData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleFilterData = useCallback(
        debounce((search_) => {
            if (!search) {
                setPage(1);
            }
            if (!search_) {
                queryClient.clear();
                setSearch("");

                return;
            }
            if (search !== search_) {
                queryClient.clear();
                setSearch(search_);
            }
        }, 3000),
        []
    );

    const handleCheckboxChange = (id: number) => {
        dispatcher({ type: "SELECT", deviceId: id });
    };

    const onDeleteClicked = (deviceData: Device) => {
        setDeleteDeviceState({ ...deviceData });
        modalDispatcher({ type: "DELETE" });
    };

    const handleIndividualDelete = async (device_id: number) => {
        await mutateAsyncDelete(device_id);
        dispatcher({ type: "DELETE" });
        queryClient.clear();
        modalDispatcher({ type: "RESET_DELETE" });
    };
    const handleIsSelectAll = useCallback(() => isSelectAll(state), [state]);

    const handleSelectAll = useCallback(() => {
        if (handleIsSelectAll()) {
            dispatcher({ type: "SELECT_ALL", deviceIds: [] });
        } else {
            const tmpArr: number[] = state.selected_devicesIds;

            state.devices.forEach(({ id }) => {
                if (!tmpArr.includes(id)) {
                    tmpArr.push(id);
                }
            });
            dispatcher({ type: "SELECT_ALL", deviceIds: tmpArr });
        }
    }, [state.devices, state.selected_devicesIds, handleIsSelectAll]);

    const handleBatchDelete = async () => {
        try {
            await mutateAsyncBatchDelete(state.selected_devicesIds);
            dispatcher({ type: "DELETE_BATCH" });
            queryClient.clear();
            modalDispatcher({ type: "RESET_DELETE_BATCH" });
        } catch (error) {
            addError({ error });
        }
    };

    const handleSort = (label: string) => {
        if (label === "name") {
            const sort = state.sortState === "NAME_ASCE" ? "NAME_DESC" : "NAME_ASCE";

            switch (sort) {
                case "NAME_ASCE":
                    dispatcher({ type: "SORT_NAME_ASC" });
                    setSortPrefs({ sort: "SORT_NAME_ASC" });
                    break;
                case "NAME_DESC":
                    dispatcher({ type: "SORT_NAME_DESC" });
                    setSortPrefs({ sort: "SORT_NAME_DESC" });
                    break;
                default:
                    break;
            }
        }
        if (label === "lisc") {
            const sort = state.sortState === "LISC_ACTIVE" ? "LISC_DEACTIVE" : "LISC_ACTIVE";

            switch (sort) {
                case "LISC_ACTIVE":
                    dispatcher({ type: "SORT_LISC_ACTIVE" });
                    setSortPrefs({ sort: "SORT_LISC_ACTIVE" });
                    break;
                case "LISC_DEACTIVE":
                    dispatcher({ type: "SORT_LISC_DEACTIVE" });
                    setSortPrefs({ sort: "SORT_LISC_DEACTIVE" });
                    break;
                default:
                    break;
            }
        }
    };

    const handleIndividualEdit = async (device_name: string) => {
        const updatedList = updateOnIndividualEdit(state.devices, editDevice.id, device_name);

        dispatcher({ type: "EDIT", data: updatedList });
        queryClient.setQueryData<DevicesListResponse>(["devices", page, row, search, product], {
            items: updatedList,
            page,
            total: state.total,
        });
    };

    const onEditClicked = (deviceData: Device) => {
        setEditDevice({ ...deviceData });
        modalDispatcher({ type: "EDIT" });
    };

    // const handleBatchEditSubmit = (device_name: string) => {
    //     const updatedList = updateOnBatchEdit(state, device_name);

    //     dispatcher({ type: "EDIT_BATCH", data: updatedList });
    //     queryClient.setQueryData<DevicesListResponse>(["devices", page, row, search, product], {
    //         items: updatedList,
    //         page,
    //         total: state.total,
    //     });
    // };

    const handleBatchInfoEditSubmit = (device_prefix: string) => {
        const updatedList = updateOnBatchInfoEdit(state, device_prefix);

        dispatcher({ type: "EDIT_BATCH", data: updatedList });
        queryClient.setQueryData<DevicesListResponse>(["devices", page, row, search, product], {
            items: updatedList,
            page,
            total: state.total,
        });
    };

    const handleLicenseStatusUpdate = (affected_device_ids: number[], status: LicenseStatus, product_id: string) => {
        const updatedList = updateOnLicenseChange(state.devices, affected_device_ids, status, product_id);

        dispatcher({ type: "UPDATE_LICENSE", data: updatedList });
        queryClient.setQueryData<DevicesListResponse>(["devices", page, row, search, product], {
            items: updatedList,
            page,
            total: state.total,
        });
    };

    const handleCustomLicenseStatusUpdate = (changes: DeviceWorkerChangeModel[], product_id: string) => {
        const updatedList = updateOnCustomLicenseChange(state.devices, changes, product_id);

        dispatcher({ type: "UPDATE_LICENSE", data: updatedList });
        queryClient.setQueryData<DevicesListResponse>(["devices", page, row, search, product], {
            items: updatedList,
            page,
            total: state.total,
        });
    };

    const handleSearchBarChange = (_data: string): void => {
        handleFilterData(_data);
    };
    const handleSearchOnEnterClick = (_data: string) => {
        if (_data !== search) {
            queryClient.clear();
            setSearch(_data);
        }
    };
    const handleSearchBarClear = () => {
        setSearch("");
        queryClient.clear();
    };
    const handleOpenLicenseBatch = () => {
        modalDispatcher({ type: "LICENSE_BATCH" });
    };
    const handleCloseLicenseBatch = () => {
        modalDispatcher({ type: "RESET_LICENSE_BATCH" });
    };
    const handleChangeRow = (newRows: number) => {
        setRow(newRows);
        setSortPrefs({ row: newRows });
    };
    const handleChangePage = (newPage: number) => {
        setPage(newPage);
        setSortPrefs({ page: newPage });
    };

    return (
        <>
            <DeviceTableContainer
                isLoading={isRefreshing}
                refetchDevices={refreshData}
                handleClearSelection={() => dispatcher({ type: "SELECT_ALL", deviceIds: [] })}
                state={state}
                handleDeleteSelected={() => modalDispatcher({ type: "DELETE_BATCH" })}
                handleEditSelected={() => modalDispatcher({ type: "EDIT_BATCH" })}
                size={state.selected_devicesIds.length}
                handleChange={handleSearchBarChange}
                handleClear={handleSearchBarClear}
                handleOpenManageLicenses={handleOpenLicenseBatch}
                isAllSelected={handleIsSelectAll()}
                row={row}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRow={handleChangeRow}
                handleSearchOnEnterClick={handleSearchOnEnterClick}
            >
                <DeviceTableHeader
                    sortState={state.sortState}
                    handleSort={handleSort}
                    isSmallScreen={isSmallScreen}
                    disableSelection={!state?.devices?.length}
                    handleSelectAll={handleSelectAll}
                    isSelection={handleIsSelectAll()}
                />
                <TableBody>
                    <DeviceTableBody
                        isLoading={isLoading || isLoadingProducts || !state.fetched || isRefreshing}
                        handleCheckboxChange={handleCheckboxChange}
                        onDeleteClicked={onDeleteClicked}
                        state={state}
                        onEditClicked={onEditClicked}
                        isSmallScreen={isSmallScreen}
                    />
                </TableBody>
                <ConfirmationModal
                    confirmButton={() => handleIndividualDelete(deleteDeviceState.id)}
                    description="The following process is irreversible, think carefully before applying changes"
                    title={`Remove device ${deleteDeviceState.device_name}?`}
                    open={modalState.delete}
                    handleCloseModal={() => modalDispatcher({ type: "RESET_DELETE" })}
                />

                <ConfirmationModal
                    confirmButton={() => handleBatchDelete()}
                    description="The following process is irreversible, think carefully before applying changes"
                    title={`Remove ${state.selected_devicesIds.length} devices?`}
                    open={modalState.delete_batch}
                    handleCloseModal={() => modalDispatcher({ type: "RESET_DELETE_BATCH" })}
                />

                {/* <DeviceBatchEditFormModal
                    device_ids={state.selected_devicesIds}
                    handleClose={() => modalDispatcher({ type: "RESET_EDIT_BATCH" })}
                    isOpen={modalState.edit_batch}
                    handleSubmit={handleBatchEditSubmit}
                /> */}

                <DeviceBatchInfoEditFormModal
                    device_ids={state.selected_devicesIds}
                    handleClose={() => modalDispatcher({ type: "RESET_EDIT_BATCH" })}
                    isOpen={modalState.edit_batch}
                    handleSubmit={handleBatchInfoEditSubmit}
                />

                <DeviceEditFormModal
                    device={editDevice}
                    handleClose={() => modalDispatcher({ type: "RESET_EDIT" })}
                    isOpen={modalState.edit}
                    handleSubmit={handleIndividualEdit}
                />
                <ManageLicenses
                    deviceIds={state.selected_devicesIds}
                    handleClose={handleCloseLicenseBatch}
                    open={modalState.license_batch}
                    handleLicenseStatusUpdate={handleLicenseStatusUpdate}
                    handleCustomLicenseStatusUpdate={handleCustomLicenseStatusUpdate}
                />
            </DeviceTableContainer>
        </>
    );
};

export default MyDevices;
